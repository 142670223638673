import Cookies from 'js-cookie';

export function hasTrackingConsent() {
  const optanonConsentCookie = Cookies.get('OptanonConsent') || '';

  if (!optanonConsentCookie) {
    return false;
  }

  const optanonConsentGroups = new URLSearchParams(optanonConsentCookie).get(
    'groups',
  );

  if (!optanonConsentGroups) return false;

  return optanonConsentGroups.split(',').some((pair) => {
    if (!pair.includes(':')) {
      return false;
    }

    const [groupId, value] = pair.split(':');

    const groupIdWithC = groupId?.startsWith('C') ? groupId : `C${groupId}`;

    if (groupIdWithC === 'C9') {
      return value === '1';
    }

    return false;
  });
}
