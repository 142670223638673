import { forwardRef } from 'react';
import { cssMerge } from '@volvo-cars/css/utils';
import { getIconSrc } from './config';
import { type IconName } from './icons.generated';

const COLOR = {
  inherit: 'text-inherit',
  inverted: 'text-inverted',
  primary: 'text-primary',
  secondary: 'text-secondary',
  'accent-blue': 'text-accent-blue',
  'feedback-green': 'text-feedback-green',
  'feedback-orange': 'text-feedback-orange',
  'feedback-red': 'text-feedback-red',
  'always-white': 'text-always-white',
  'always-black': 'text-always-black',
} as const;

export type IconColor = keyof typeof COLOR | 'currentColor';

export type IconProps = {
  /**
   * The name of the icon in the 1.0 icon system.
   */
  icon: IconName;

  /**
   * The size of the icon in pixels, when using the `icon` property.
   *
   * Sizes 12 and 16 will use a smaller, simplified icon asset.
   */
  size: 12 | 16 | 24 | 32 | 40 | 48 | 64 | 80;

  /**
   * Use the filled variant of the icon, typically for active or selected states.
   *
   * This property is only used together with the `icon` property.
   */
  filled?: boolean;

  /**
   * By default icons use the primary foreground color.
   *
   * To change color together with the surrounding text, such as when hovering a button,
   * use `currentColor` or `inherit`.
   */
  color?: IconColor;

  /**
   * Custom class name, merged with existing classes.
   */
  className?: string;

  /**
   * Alternate text.
   *
   * May be omitted if the icon is purely decorational or if the alternate text is already present,
   * such as in a button that already has a label.
   */
  alt?: string;

  hidden?: boolean;
  id?: string;
  title?: string;
  dir?: string;
  lang?: string;
  slot?: string;
  style?: React.CSSProperties;

  onAnimationEnd?: React.AnimationEventHandler<HTMLImageElement>;
  onAnimationStart?: React.AnimationEventHandler<HTMLImageElement>;
  onTransitionEnd?: React.TransitionEventHandler<HTMLImageElement>;
};

/**
 * Icon renders an `img` with a src on the volvocars.com CDN.
 *
 * By default icons use the primary foreground color. To change color together with the surrounding text,
 * such as when hovering a button, use `color="currentColor"` to inherit the surrounding `color`.
 *
 * To create a clickable icon, see [IconButton](https://developer.volvocars.com/design-system/web/?path=/docs/components-iconbutton--docs).
 */
export const Icon = forwardRef<HTMLImageElement, IconProps>(function Icon(
  { alt, color, icon, size, filled = false, ...props },
  ref
) {
  const iconSrc = getIconSrc(icon, size, filled);
  const sprite = !color;
  const className = cssMerge(
    sprite ? 'icon-sprite' : 'icon-mask',
    color && COLOR[color as keyof typeof COLOR],
    props.className
  );
  return (
    <img
      {...props}
      ref={ref}
      className={className}
      width={size}
      height={size}
      src={iconSrc}
      role={alt ? undefined : 'presentation'}
      alt={alt || ''}
      style={
        sprite
          ? props.style
          : ({
              '--icon-url': `url(${iconSrc})`,
              ...props.style,
            } as React.CSSProperties)
      }
    />
  );
});
