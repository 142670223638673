/* eslint-disable no-useless-escape */
/* This file is generated by generate-supported-browsers.ts */

export const supportedBrowsers =
  /Edge?\/1{2}[2-6]\.0(\.\d+|)|Firefox\/1{2}[2-6]\.0(\.\d+|)|Chrom(ium|e)\/1(0[7-9]|1[0-6])\.0(\.\d+|)|(Maci|X1{2}).+ Version\/(14\.[01]|15\.[0-6]|16\.[0-5])([,.]\d+|)( \(\w+\)|)( Mobile\/\w+|) Safari\/|(CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS|CPU iPad OS)[ +]+(14[._][0-8]|15[._][0-7]|16[._][0-5])([._]\d+|)|Android.+Chrom(ium|e)\/1{2}6\.0(\.\d+|)|SamsungBrowser\/2[01]\.0/;

export const browsersInfo = [
  {
    family: 'edge',
    regExp: /Edge?\/(\d+)\.(\d+)(\.(\d+)|)/,
    min: ['112', '0', '0'],
  },
  {
    family: 'firefox',
    regExp: /Firefox\/(\d+)\.(\d+)(\.(\d+)|)/,
    min: ['112', '0', '0'],
  },
  {
    family: 'chrome',
    regExp: /Chrom(ium|e)\/(\d+)\.(\d+)(\.(\d+)|)/,
    min: ['107', '0', '0'],
  },
  {
    family: 'safari',
    regExp:
      /(Maci|X11).+ Version\/(\d+)\.(\d+)([.,](\d+)|)( \(\w+\)|)( Mobile\/\w+|) Safari\//,
    min: ['14', '0', '0'],
  },
  {
    family: 'ios_saf',
    regExp:
      /(CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS|CPU iPad OS)[ +]+(\d+)[_.](\d+)([_.](\d+)|)/,
    min: ['14', '0', '0'],
  },
  {
    family: 'and_chr',
    regExp: /Android.+Chrom(ium|e)\/(\d+)\.(\d+)(\.(\d+)|)/,
    min: ['116', '0', '0'],
  },
  {
    family: 'samsung',
    regExp: /SamsungBrowser\/(\d+)\.(\d+)/,
    min: ['20', '0', '0'],
  },
];
